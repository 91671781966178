const awsSettings = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:c777ffb1-e53b-4117-a362-08cef62345f9",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_DvQtNZnHD",
  aws_user_pools_web_client_id: "4fa6udf5n0inncut01038detmd",
  oauth: {
    domain: "essl-user-pool.auth.us-east-1.amazoncognito.com",
    scope: ["email", "openid"],
    redirectSignIn: "https://essl.tools.vonagenetworks.net/redirect/",
    redirectSignOut: "https://essl.tools.vonagenetworks.net/redirect/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  API: {
    endpoints: [
      {
        name: "esslapi",
        endpoint: "https://3hm1quta56.execute-api.us-east-1.amazonaws.com/dev",
      },
    ],
  },
};

export default awsSettings;
