import { API, Auth } from "aws-amplify";
import {
  Button,
  Link,
  Loader,
  Radio,
  RadioGroupField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextAreaField,
  TextField,
  Text,
} from "@aws-amplify/ui-react";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const valid_user_email_domains = [ "vonage.com", "ericsson.com" ];
const is_valid_email = (email: string) => {
  const [_, domain] = email.split("@")
  if (domain) return valid_user_email_domains.includes(domain.trim().toLowerCase());
  else return false;
};

interface Cert {
  id: number;
  type: string;
  expiration: string;
  expiring_soon: boolean;
  domain_name: string;
  additional_names: string[];
  account_name: string;
  account_id: string;
  status: string;
  notes: string;
  jira: string[];
  contacts: string[];
}

interface PostCert {
  user_email: string;
  status: string;
  notes: string;
  add_jira: string[];
  add_emails: string[];
}

const getUserEmail = () =>
  Auth.currentAuthenticatedUser().then(
    (user) => user.getSignInUserSession()?.getIdToken().payload.email
  );

const api_name = "esslapi";
const fetchCert = (cert_id: string, cert_type: string): Promise<Cert> =>
  getUserEmail().then((user_email) =>
    API.get(api_name, "/cert/" + cert_type + "/" + cert_id, {
      queryStringParameters: { user_email },
    })
  );

const postCert = (cert: Cert, add_jira: string[], add_emails: string[]) =>
  getUserEmail().then((user_email) => {
    const body: PostCert = {
      user_email,
      status: cert.status,
      notes: cert.notes,
      add_jira,
      add_emails,
    };
    return API.post(api_name, "/cert/" + cert.type + "/" + cert.id, { body });
  });

const CertForm: FC = () => {
  const { cert_id, cert_type } = useParams();
  const [isSaving, setIsSaving] = useState(false);

  const [cert, setCert] = useState<Cert | null>(null);
  const [status, setStatus] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [newJira, setNewJira] = useState<string[]>([]);
  const [newContacts, setNewContacts] = useState<string[]>([]);
  const [reminderText, setReminderText] = useState<string>("");

  // get certificate on mount
  useEffect(() => {
    if (cert_id && cert_type)
      fetchCert(cert_id, cert_type).then((cert) => {
        cert.jira = cert.jira ?? [];
        cert.notes = cert.notes ?? "";
        setCert(cert);
        setNotes(cert.notes);
        setStatus(cert.status);
        setNewJira([]);
        setNewContacts([]);
      });
  }, [cert_id, cert_type]);

  if (cert) {
    return (
      <>
        <Table variation="bordered">
          <TableBody>
            <TableRow>
              <TableCell>Domain Name:</TableCell>
              <TableCell>{cert.domain_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Additional Names:</TableCell>
              <TableCell>{cert.additional_names.join(", ")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type:</TableCell>
              <TableCell>{cert.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Expiration:</TableCell>
              <TableCell>{cert.expiration}</TableCell>
            </TableRow>
            {cert.type.toLowerCase() !== "digicert" && (
              <>
                <TableRow>
                  <TableCell>Account Name:</TableCell>
                  <TableCell>{cert.account_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account ID:</TableCell>
                  <TableCell>{cert.account_id}</TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell>Status:</TableCell>
              <TableCell>
                <RadioGroupField
                  name="status"
                  label="Status"
                  labelHidden={true}
                  isRequired={true}
                  //   errorMessage="Status cannot be left Unacknowledged"
                  //   hasError={!status}
                  value={status}
                  onChange={({ target }) => setStatus(target.value)}
                >
                  <Radio value="ack">
                    Acknowledged - I will be renewing this certificate and would
                    like to stop receiving daily reminders.
                  </Radio>
                  <Radio value="na">
                    No Longer Needed - This certificate is no longer valid or
                    in-use and can be left to expire.
                  </Radio>
                </RadioGroupField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Notes:</TableCell>
              <TableCell>
                <TextAreaField
                  label="Notes"
                  labelHidden={true}
                  value={notes}
                  onChange={({ target }) => setNotes(target.value)}
                ></TextAreaField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>JIRA:</TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    {cert.jira.map((url) => (
                      <TableRow>{url}</TableRow>
                    ))}
                    {newJira.map((url, index) => (
                      <TableRow>
                        <TextField
                          label={url}
                          labelHidden={true}
                          onChange={({ target }: any) => {
                            let newValue = newJira.slice(0);
                            newValue[index] = target.value;
                            setNewJira(newValue);
                          }}
                          outerEndComponent={
                            <Button
                              onClick={() =>
                                setNewJira(
                                  newJira.filter((_, i) => i !== index)
                                )
                              }
                            >
                              -
                            </Button>
                          }
                        ></TextField>
                      </TableRow>
                    ))}
                    <Button onClick={() => setNewJira([...newJira, ""])}>
                      + Add JIRA
                    </Button>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contacts:</TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    {cert.contacts.map((contact) => (
                      <TableRow>{contact}</TableRow>
                    ))}
                    {newContacts.map((contact, index) => (
                      <TableRow>
                        <TextField
                          label={contact}
                          labelHidden={true}
                          onChange={({ target }: any) => {
                            let newValue = newContacts.slice(0);
                            newValue[index] = target.value;
                            setNewContacts(newValue);
                          }}
                          outerEndComponent={
                            <Button
                              onClick={() =>
                                setNewContacts(
                                  newContacts.filter((_, i) => i !== index)
                                )
                              }
                            >
                              -
                            </Button>
                          }
                          hasError={!is_valid_email(contact)}
                          errorMessage={"Contacts must be @" + valid_user_email_domains.join(" or @")}
                        ></TextField>
                      </TableRow>
                    ))}
                    <Button
                      onClick={() => setNewContacts([...newContacts, ""])}
                    >
                      + Add Contact
                    </Button>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button
          isLoading={isSaving}
          loadingText="Saving"
          disabled={
            status === cert.status &&
            notes === cert.notes &&
            !newContacts.length &&
            !newJira.length
          }
          isFullWidth={true}
          variation="primary"
          onClick={() => {
            setIsSaving(true);
            let waitTime = 1000;
            if (!status) {
              setReminderText(
                "Please remember to update the status, or you will continue to receive notifications"
              );
              waitTime = 5000;
            }
            const waitToReload = new Promise((r) => setTimeout(r, waitTime));
            postCert({ ...cert, status, notes }, newJira, newContacts).then(
              () => waitToReload.then(() => window.location.reload())
            );
          }}
        >
          Save
        </Button>
        {reminderText && <Text>{reminderText}</Text>}
        <Link
          target="_blank"
          href={
            "https://detective.vbsrva0.sre.prd.vonagenetworks.net/digicert_orders/" +
            cert.id
          }
        >
          View in Detective
        </Link>
      </>
    );
  } else return <Loader></Loader>;
};

export default CertForm;
