import { FC, ReactNode, useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";

import type { CognitoUser } from "@aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider, Flex, Heading, Image, View } from "@aws-amplify/ui-react";

import "./App.css";
import aws_settings from "./aws-settings";
// import aws_settings from "./aws-exports";
// import awsauth from "./awsauth.json";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import theme from "./theme";
import CertForm from "./CertForm";
import logo from "./logo.svg";

Amplify.configure(aws_settings);
// Auth.configure(awsauth);

const OauthRedirect: FC<any> = ({ user, setUser }) => {
  const [state, setState] = useState<string>();
  useEffect(() =>
    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn") setUser(data);
      else if (event === "customOAuthState") setState(data);
    })
  );
  if (user && state) return <Navigate to={state}></Navigate>;
  else return <></>;
};

const RequireAuth: FC<{ user?: CognitoUser; children?: ReactNode }> = ({
  user,
  children,
}) => {
  const location = useLocation();
  if (user) return <>{children}</>;
  else {
    Auth.federatedSignIn({
      provider: "OKTA" as any,
      customState: location.pathname,
    });
    return <></>;
  }
};

const App = () => {
  const [user, setUser] = useState<CognitoUser>();
  return (
    <AmplifyProvider theme={theme}>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        textAlign="center"
      >
        <Image src={logo} alt="Amplify" width="17.6rem"/>
        <Heading>Certificate Expiration Form</Heading>
        <BrowserRouter>
          <Routes>
            <Route
              path="/redirect"
              element={<OauthRedirect user={user} setUser={setUser} />}
            />
            <Route
              path="/cert/:cert_type/:cert_id"
              element={
                <RequireAuth user={user}>
                  <View>
                    <CertForm></CertForm>
                  </View>
                </RequireAuth>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </Flex>
    </AmplifyProvider>
  );
};

export default App;
